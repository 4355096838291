
import * as storyblokRuntime$myt8WKkYqLJ1SN_txkTFIbyrjiHNI8ep7UFVHyxKrTs from '/codebuild/output/src3069017492/src/bitbucket.org/magusa/cavu-workspaces/node_modules/.pnpm/@nuxt+image@1.10.0_db0@0.3.1_ioredis@5.6.0_magicast@0.3.5/node_modules/@nuxt/image/dist/runtime/providers/storyblok'
import * as ipxRuntime$T7lVcOeGy_454q_45vDs_1VRy_JeundQo4BhGEz5XSzdm_458 from '/codebuild/output/src3069017492/src/bitbucket.org/magusa/cavu-workspaces/node_modules/.pnpm/@nuxt+image@1.10.0_db0@0.3.1_ioredis@5.6.0_magicast@0.3.5/node_modules/@nuxt/image/dist/runtime/providers/ipx'

export const imageOptions = {
  "screens": {
    "xs": 320,
    "sm": 640,
    "md": 768,
    "lg": 1024,
    "xl": 1280,
    "xxl": 1536,
    "2xl": 1536
  },
  "presets": {},
  "provider": "ipx",
  "domains": [],
  "alias": {},
  "densities": [
    1,
    2
  ],
  "format": [
    "webp"
  ]
}

imageOptions.providers = {
  ['storyblok']: { provider: storyblokRuntime$myt8WKkYqLJ1SN_txkTFIbyrjiHNI8ep7UFVHyxKrTs, defaults: {"baseURL":"https://a.storyblok.com"} },
  ['ipx']: { provider: ipxRuntime$T7lVcOeGy_454q_45vDs_1VRy_JeundQo4BhGEz5XSzdm_458, defaults: {} }
}
        